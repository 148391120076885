<template>
    <KCourse loader-class="MBcont" course-id="9" title="Эмоции" :items="items">
        <div class="MBtextcont">           
            <p>Начнем с основы, согласитесь, сложно создавать эмоции без лица 😆, поэтому лепим либо одно лицо, либо три (по количеству эмоций), кому как удобно )</p>
            <p>Первый урок вас уже ждет!</p>
        </div>

        <VideoView video-id="f319cb8b19614aab9066e97ca701ad07"/>

        <div class="MBtextcont">           
            <p>Так же вас ждёт чек-лист по построению лица и по всему тому, что нужно знать, чтобы результат вас радовал!</p>

            <a href="/files/Emotions/1Base.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/miniland/check-list.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style>

</style>